import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { rhythm, scale } from "../utils/typography"
import Header from "./header"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  const betaBadgeStyle = css`
    background-color: #3cb55b;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 10px;
    padding: 4px 6px;
    margin: 0px 4px;
  `
  const headerSyle = css`
    line-height: 1.5;
    align-items: center;
    height: 3rem;
    font-size: 1.5rem;
    font-family: Kavoon, Galindo;
    //color: white;
    //background-color: #24292e;
    width: 100%;
    display: flex;
    margin-top: 0;
    padding-left: 6px;
  `

  header = (<Header title={title}></Header>)

  const twitterAStyle = css`
    box-shadow: none;
    text-decoration: none;
    -webkit-text-decoration: none;
    color: #000000;
    &:hover {
      color: #78757a;
    }
  `
  
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(32),
        padding: `0 ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        <p style={{ fontSize: '0.8rem', textAlign: 'center', marginTop: '50px' }}>
        © {new Date().getFullYear()} <a css={twitterAStyle} href="https://twitter.com/vidkidz" target="_blank" rel="noopener noreferrer">vidkidz</a>. All rights reserved.
        {` `}
        </p>
      </footer>
    </div>
  )
}

export default Layout
