import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.googleFonts = [{
  name: "Noto Sans KR",
  styles: ["400", "700"]
}, {
  name: "Montserrat",
  styles: ["700"]
}, {
  name: "Galindo",
  styles: ["400"]
}, {
  name: "Modak", // Kavoon // Monofett
  styles: ["400"]
}, {
  name: "Merriweather",
  styles: ["400", "400i", "700", "700i", "900", "900i"]
}]
Wordpress2016.headerFontFamily = ["Merriweather", "Noto Sans KR", "serif"], // "Georgia", 
Wordpress2016.bodyFontFamily = ["Noto Sans KR", "serif"], // "Georgia", "Merriweather"
Wordpress2016.baseLineHeight = 1.5, //1.75,

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    h1: {
      fontFamily: ["Montserrat", "Noto Sans KR", "sans-serif"].join(",")
    },
    "ul,ol": {
      marginLeft: '1.5rem',
    },
    breakpoints: {
      "@media only screen and (max-width:768px)": { // any valid media query.
        scale: 1.618, // Override the default scale
        fontSize: '95%', // Change body font size.
      },
      "@media only screen and (max-width:480px)": { // any valid media query.
        fontSize: '85%',
      },
    }
  }
}

//delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
