import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { rhythm, scale } from "../utils/typography"

// 654ff0

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
        logo: file(absolutePath: { regex: "/waflash-logo.png/" }) {
            childImageSharp {
                fixed(width: 24, height: 24) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
  `)

  const betaBadgeStyle = css`
    background-color: #3cb55b;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 10px;
    padding: 2px 4px;
    margin: 0px 4px;
  `
  const headerSyle = css`
    position: relative;
    line-height: 1.5;
    align-items: center;
    height: 3rem;
    font-size: 1.5rem;
    font-family: Modak, Kavoon, Galindo;
    //color: white;
    //background-color: #24292e;
    width: 100%;
    display: flex;
    margin-top: 0;
    //padding-left: 6px;
  `

  const wafStyle = css`
    position: absolute;
    //font-family: Noto Sans KR;
    font-size: 0.8rem;
    color: white;
    margin: 0px;
    padding: 0px;
    left: 15px;
    top: 20px;

  `

  // <div css={wafStyle}>f</div>

  return (
    <div css={headerSyle}>
      <div>
        <a
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          href={`/`}
        >
            <Img style={{ verticalAlign: 'middle', marginRight: '5px' }} fixed={data.logo.childImageSharp.fixed} />
            {props.title}
        </a>
        {/* <div css={betaBadgeStyle}>v0.1</div> */}
      </div>
      
    </div>
  )
}

export default Header
